import { useState, useContext } from "react";
import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { Product } from "../../../../contexts/product";
import Cookies from 'universal-cookie';



export function OneProduct({ data, unitPreco }) {
    const cookies = new Cookies()
    const [collOpen, setCollOpen] = useState(false)
    const theme = useTheme()
    const product = useContext(Product)
    const freteGratis = {
        unidades: 3,
        ative: true
    }
    function handleClick() {
        const x = { ...product?.data };
        x.activeItem = data;
        cookies.set('cart', data);
        function Fcallback() {
            console.log('aaaaa');
        }
        product?.updateProduct(x, Fcallback);
    }
    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', width: 300 }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                {freteGratis.ative && data?.quantidade >= freteGratis.unidades ?
                    <Box sx={{ background: theme.palette.tertiary.main, padding: 1, maxWidth: '40%' }}>
                        <Typography sx={{ fontWeight: 'bold', color: '#fff' }}>Frete Grátis</Typography>
                    </Box>
                    : null}
            </Box>
            <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
                <img src={data?.image} style={{ width: 200 }} alt={data?.nome} />
                <Typography sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>{data?.nome}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2, paddingBottom: 0, alignSelf: 'flex-start', width: '90%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Preço: </Typography>
                        <Typography>{'R$' + data?.preco.toFixed(2).toString()}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Preço unidade: </Typography>
                        <Typography>{'R$' + formatUnidade(data?.preco, data?.quantidade)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Desconto: </Typography>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main, fontSize: 20 }}>{desconto(unitPreco, parseFloat(formatUnidade(data?.preco, data?.quantidade))) + '%'}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: 1, background: theme.palette.background.default, display: 'flex', justifyContent: 'center' }}>
                <Button component={Link} to={'/carrinho?home=true'} onClick={handleClick} sx={{ background: theme.palette.primary.main, color: '#fff', padding: 2, paddingLeft: 5, paddingRight: 5, fontWeight: 'bold', textTransform: 'none' }}>Comprar</Button>
            </Box>
        </Box>
    )
}


function formatUnidade(preco, quantidade) {
    return (preco / quantidade).toFixed(2).toString()
}

function desconto(precoUnidade, precoCombo) {
    return Math.abs(((precoCombo - precoUnidade) / precoUnidade) * 100).toFixed(2).toString()
}