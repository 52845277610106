import React, { useContext, useEffect, useRef, useState } from "react"
import { Product } from "../../contexts/product";
import { useTheme } from '@mui/material/styles';
import { ModalLoading } from "../../components/modal";
import { Box, Typography, Divider } from '@mui/material';
import Registro from "./registro";
import Endereco from "./endereco";
import Entrega from "./entrega";
import Produtos from "./produtos";
import Pagamento from "./pagamento";
import ModalPagamento from "../../components/modalPagamento";
import Resumo from "./resumo";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import 'swiper/swiper-bundle.css';
import mainApi from "../../apis/mainApi";
import StatusScreen from "../../components/statusScreen";
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomizedSteppers from '../../components/stepper'
import { SnackBarAlert, SnackBarSucess } from "../../components/snackbar";
import { User } from "../../contexts/user";
import { useLocation } from "react-router-dom";
import { Pixel } from "../../components/pixel";

export default props => {
    document.title = 'Carrinho'
    const product = useContext(Product)
    const user = useContext(User)
    const theme = useTheme();
    const [modalLoading, setModalLoading] = useState(false)
    const [statusScreen, setStatusScreen] = useState({
        active: false,
        id: null,
        paymentMethod: null,
        serverId: null,
        dateCreated: null
    })
    const [snackBar, setSnackBar] = useState({
        id: 1,
        open: false,
        message: ""
    })
    const [opens, setOpens] = useState({
        registro: {
            open: true,
            canOpen: true,
        },
        produto: {
            open: false,
            canOpen: false,
        },
        endereco: {
            open: false,
            canOpen: false
        },
        entrega: {
            open: false,
            canOpen: false
        },
        pagamento: {
            open: false,
            canOpen: false,
            inicialization: null,
            customization: null
        }
    })
    const [form, setForm] = useState({
        cpf: '',
        primeiro_nome: '',
        sobrenome: '',
        email: '',
        senha: '',
        ddd: '',
        celular: '',
        cep: user?.data?.cep ? user?.data?.cep : '',
        rua: user?.data?.rua ? user?.data?.rua : '',
        numero: user?.data?.numero ? user?.data?.numero : '',
        bairro: user?.data?.bairro ? user?.data?.bairro : '',
        cidade: user?.data?.cidade ? user?.data?.cidade : '',
        estado: user?.data?.estado ? user?.data?.estado : '',
        complemento: user?.data?.complemento ? user?.data?.complemento : '',
        salvarEndereco: false,
        amount: null,
        cupom: {
            id: null,
            parceiro_id: null
        },
        entrega: {
            id: null,
            name: '',
            preco: null,
            prazo: null,
            load: false
        },
        pagamento: {
            inicialization: null,
            customization: null,
            open: false
        }
    })
    async function submitPayment(value) {
        function FCallback(callback) {
            setModalLoading(false)
            if (callback.status === 200) {
                desactiveModalPayment()
                const x = { ...statusScreen }
                x.active = true
                x.id = '' + callback?.apiReturn?.apiReturn?.id + ''
                x.paymentMethod = callback?.apiReturn?.apiReturn?.paymentMethod
                x.serverId = callback?.apiReturn?.apiReturn?.idServer
                x.dateCreated = callback?.apiReturn?.apiReturn?.pedidoData
                setStatusScreen(x)
                return
            }
            else {
                const x = { ...snackBar }
                x.message = callback.apiReturn.message ? callback.apiReturn.message : 'Não foi possível realizar o pagamento'
                x.open = true
                x.id = 1
                setSnackBar(x)
                setTimeout(() => {
                    desactiveModalPayment()
                }, 500)
            }
        }
        if (!value?.formData?.payer?.identification) {
            value.formData.payer.identification = {
                type: 'CPF',
                number: user.data.cpf
            }
        }
        value.cupom = form.cupom
        value.product = product.data.activeItem.id
        value.productPrice = parseFloat(product.data.activeItem.preco)
        value.endereco = {
            rua: form.rua,
            numero: form.numero,
            bairro: form.bairro,
            cidade: form.cidade,
            estado: form.estado,
            cep: form.cep,
            entrega: { id: form.entrega.id, preco: parseFloat(form.entrega.preco), prazo: form.entrega.prazo, nome: form.entrega.name, peso: product.data.activeItem.nVlPeso, comprimento: product.data.activeItem.nVlComprimento, altura: product.data.activeItem.nVlAltura, largura: product.data.activeItem.nVlLargura, diametro: product.data.activeItem.nVlDiametro, formato: product.data.activeItem.nCdFormato },
        }
        value.cliente = user.data
        const getParceiro = sessionStorage.getItem('utmParameters')
        if (getParceiro) {
            value.parceiro = JSON.parse(getParceiro)
        }
        if (value?.formData?.payer)
            await mainApi('process_payment', 'POST', value, FCallback)
    }
    function readyPayment(value) {
        //setModalLoading(false)
    }
    function errorPayment(value) {
        console.log(value, 'zzzzzzzzzzzzzzzzzzzz')
    }
    function updateForm(value) {
        setForm(value)
    }
    function changeOpen(value, value2) {
        if (value2 || value2 === 0) {
            swiperRef.current?.swiper?.slideTo(value2)
            return
        }
        if (value === 1) {
            swiperRef.current?.swiper?.slideNext()
            return
        }
        swiperRef.current?.swiper?.slidePrev()
        //setOpens(value)
    }
    function closeModal(ative) {
        if (ative) {
            setModalLoading(true)
            return
        }
        setModalLoading(false)
    }

    function desactiveModalPayment() {
        const x = { ...form }
        x.pagamento.open = false
        setModalLoading(false)
        setForm(x)
    }
    const swiperRef = useRef()
    function desactiveStatusScreen() {
        const x = { ...statusScreen }
        x.active = false
        window.statusScreenBrickController.unmount()
        setStatusScreen(x)
    }
    function closeSnack() {
        const x = { ...snackBar }
        x.open = false
        setSnackBar(x)
    }
    function updateSnackBar(value) {
        setSnackBar(value)
    }
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const scrollToTarget = (value, init) => {
        if (init) {
            var x = query.getAll('home')
            console.log(product.data.activeItem)
            if (x[0] === 'true' && product.data.activeItem.preco) {
                value.slideTo(1)
            }
        }
        const target = document.getElementById('init');
        if (target) {
            target.scrollIntoView({ behavior: 'smooth' });
        }
    };
    Pixel()
    useEffect(() => {
    }, [])
    useEffect(() => {

        if (!['', undefined, null].includes(user.data.cep)) {
            const newForm = { ...form }
            newForm.cep = user.data.cep ? user.data.cep : ''
            newForm.rua = user.data.rua ? user.data.rua : ''
            newForm.numero = user.data.numero ? user.data.numero : ''
            newForm.bairro = user.data.bairro ? user.data.bairro : ''
            newForm.cidade = user.data.cidade ? user.data.cidade : ''
            newForm.estado = user.data.estado ? user.data.estado : ''
            newForm.complemento = user.data.complemento ? user.data.complemento : ''
            newForm.salvarEndereco = true
            setForm(newForm)
        }
    }, [user])
    if (!product?.data) {
        return
    }
    return (
        <Box id="init" bgcolor={theme.palette.background.paper} sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', paddingBottom: '40px' }}>
            <Box flexGrow={1} width={'100%'} bgcolor={theme.palette.background.paper} display={'flex'} justifyContent={'center'} alignItems={'flex-start'} gap={1} sx={{
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    gap: 2,
                },
            }}>
                <Box sx={{
                    width: '70%',
                    bgcolor: theme.palette.background.default,
                    minHeight: 500,
                    marginTop: 4,
                    [theme.breakpoints.down('md')]: {
                        width: '100%'
                    },
                }}>
                    <Swiper onInit={(value) => scrollToTarget(value, true)} ref={swiperRef} allowTouchMove={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} autoHeight onSlideChange={() => {
                        scrollToTarget()
                    }}>
                        <SwiperSlide>
                            <Produtos changeOpen={changeOpen} open={opens} form={form} updateForm={updateForm} setSnackBar={updateSnackBar} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Endereco changeOpen={changeOpen} open={opens} form={form} updateForm={updateForm} setSnackBar={updateSnackBar} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Entrega changeOpen={changeOpen} open={opens} form={form} updateForm={updateForm} modalLoading={closeModal} setSnackBar={updateSnackBar} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Resumo changeOpen={changeOpen} open={opens} form={form} updateForm={updateForm} modalLoading={closeModal} setSnackBar={updateSnackBar} />
                        </SwiperSlide>
                    </Swiper>
                </Box>
            </Box>
            <ModalPagamento initialization={form.pagamento.inicialization} active={form.pagamento.open} onSubmit={submitPayment} onReady={readyPayment} onError={errorPayment} customization={form.pagamento.customization} onClose={desactiveModalPayment} />
            <ModalLoading ative={modalLoading} closeModal={closeModal} />
            <StatusScreen form={form} active={statusScreen.active} id={statusScreen.id} paymentMethod={statusScreen.paymentMethod} desactive={desactiveStatusScreen} serverId={statusScreen.serverId} dateCreated={statusScreen.dateCreated} />
            <SnackBarAlert ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <SnackBarSucess ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
        </Box>
    )
}