import { Button, useTheme } from "@mui/material";
import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";


export function ButtonComprar({ onClick }) {
    const theme = useTheme()
    const bounceAnimation = keyframes`
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
`;
    return (
        <Button onClick={onClick} sx={{
            background: theme.palette.tertiary.main, animation: `${bounceAnimation} 0.8s ease-in-out infinite`, color: '#fff', fontWeight: 'bold', width: '100%', maxWidth: 280, textTransform: 'none', fontSize: 25, '&:hover': {
                background: theme.palette.primary.rgba
            }
        }}>Comprar agora <img src="../assets/images/components/arrow.png" style={{ paddingLeft: '20px' }} /></Button>
    )
}