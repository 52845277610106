import { Box, Button, Typography, useTheme, useMediaQuery, Fade } from "@mui/material";
import { ButtonComprar } from "../../../components/ButtonComprar";
import { useInView } from 'react-intersection-observer';


export function Section1({ }) {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const tytleStyle = {
        fontWeight: 'bold',
        fontSize: isSmallScreen ? 30 : 40,
        textAlign: 'center',
        color: theme.palette.text.primary
    };

    const subtitleStyle = {
        fontSize: isSmallScreen ? 16 : 20,
        color: theme.palette.text.primary,
        textAlign: 'center',
        padding: 0
    };
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    })
    const scrollToTarget = () => {
        const target = document.getElementById('Combos');
        if (target) {
            target.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        }
    };
    return (
        <Fade in={inView} timeout={1000}>
            <Box ref={ref} id={'Componentes'} sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                gap: 2,
                flex: 1,
                padding: isSmallScreen ? 2 : 10,
                paddingTop: 2,
                paddingBottom: 2
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 5, alignItems: 'center' }}>
                    <Box>
                        <Typography sx={tytleStyle}>LIBERTE-SE DA HERPES COM <Typography component="span" sx={[tytleStyle, { color: theme.palette.primary.main }]}>HERPSCALM!</Typography></Typography>
                        <Typography sx={subtitleStyle}>A solução definitiva para prevenir e tratar surtos de <Typography component='span' sx={[subtitleStyle, { color: theme.palette.primary.main, fontWeight: 'bold' }]}> Herpes.</Typography></Typography>
                    </Box>
                    {isMobile ? null :
                        <ButtonComprar onClick={scrollToTarget} sx={{ background: theme.palette.tertiary.main, color: '#fff', fontWeight: 'bold' }} />
                    }
                </Box>
                <Box>
                    <img alt={'Herpscalm'} src="../assets/images/home/section2/herpscalm.png" style={{ width: isSmallScreen ? 300 : 400 }} />
                </Box>
                {isMobile ?
                    <ButtonComprar onClick={scrollToTarget} sx={{ background: theme.palette.tertiary.main, color: '#fff', fontWeight: 'bold' }} /> : null
                }
            </Box>
        </Fade>
    )
} 