var CryptoJS = require("crypto-js");

export const encrypt = (value) => {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), 'smashpointdarwin*').toString();
    return (ciphertext)
}

export const decrypt = (value) => {
    try {
        var bytes = CryptoJS.AES.decrypt(value, 'smashpointdarwin*');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return (decryptedData)
    }
    catch (error) {
        console.log(error)
        return ''
    }
}

export function saveUtms(location) {
    const verifyExist = sessionStorage.getItem('utmParameters')
    if (verifyExist) return
    const urlParams = new URLSearchParams(location)
    const utmSource = urlParams.get('utm_source');
    if (utmSource) {
        const utmParceiro = urlParams.get('utm_parceiro');
        const utmCampaign = urlParams.get('utm_campaign');
        const utmParameters = {
            source: utmSource,
            parceiro: utmParceiro,
            campaign: utmCampaign
        }
        sessionStorage.setItem('utmParameters', JSON.stringify(utmParameters))
    }
}