import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Checkbox } from '@mui/material';
import { Product } from "../contexts/product";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';


export default props => {
    const cookies = new Cookies()
    const theme = useTheme();
    const product = useContext(Product)
    var swiperRef = useRef()
    const [controler, setController] = useState({
        slides: [],
        activeItem: 0
    })
    const freteGratis = {
        unidades: 3,
        ative: true
    }
    const buttonStyle = {
        backgroundColor: '#19FF88',
        padding: 2,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#15DC75',
        }
    }
    function getPrecoParcela(preco) {
        preco = preco * 1.2161
        return (preco / 12).toFixed(2)
    }
    const slideRef = useRef()
    function sortedData() {
        const newData = [...product?.data?.prices]
        return newData.sort((a, b) => a - b).reverse()
    }
    const home = props.home

    useEffect(() => {
        //console.log(slideRef.current)
    }, [slideRef.current])
    return (
        <Box width={'100%'} overflow={'visible'}>
            <Swiper
                ref={swiperRef}
                slidesPerView={1}
                modules={{ Pagination, Navigation }}
                style={{ marginBottom: 5, overflowY: 'visible' }}
                onInit={(value) => {
                    /* console.log(value.slides.map((element, index) => {
                        console.log(element, index)
                    })) */
                    const x = { ...controler }
                    x.slides = value.snapGrid
                    x.activeItem = value.activeIndex
                    setController(x)
                }}
                breakpoints={{
                    700: {
                        slidesPerView: 2,
                    },
                    1500: {
                        slidesPerView: 3,
                    }
                }}
                onBeforeResize={(value) => {
                    if (value.snapGrid.length !== controler.slides.length) {
                        const x = { ...controler }
                        x.slides = value.snapGrid
                        x.activeItem = value.activeIndex
                        setController(x)
                    }
                }}
                onSlideChange={(value) => {
                    const x = { ...controler }
                    x.slides = value.snapGrid
                    x.activeItem = value.activeIndex
                    setController(x)
                }}
            >
                {useMemo(() => {
                    return (
                        <Box>
                            {sortedData().map((e, idx) => {
                                const newProduto = product?.data?.prices.filter((e) => e?.quantidade === 1)
                                const classname = product?.data?.activeItem?.id === e?.id ? 'productAtive' : 'productInative'
                                return (
                                    <SwiperSlide key={idx}
                                        className={classname}
                                    >
                                        <Button
                                            onClick={() => {
                                                const x = { ...product?.data };
                                                x.activeItem = e;
                                                cookies.set('cart', e);
                                                function Fcallback() {
                                                    console.log('aaaaa');
                                                }
                                                product?.updateProduct(x, Fcallback);
                                            }}
                                            sx={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)', // Sombra mais proeminente
                                                borderRadius: '8px', // Cantos arredondados
                                                scale: home && idx === 0 ? '1,06' : 'unset',
                                                transition: 'transform 0.2s ease',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    border: 1,
                                                    borderColor: theme.palette.background.default,
                                                    padding: '16px',
                                                    width: '100%',
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '8px',
                                                    minHeight: '450px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: home ? 'flex-end' : 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {home ? null :
                                                        <Checkbox
                                                            checked={product?.data?.activeItem?.id === e?.id}
                                                            onClick={() => {
                                                                const x = { ...product?.data };
                                                                x.activeItem = e;
                                                                function Fcallback() {
                                                                    console.log('aaaaa');
                                                                }
                                                                product?.updateProduct(x, Fcallback);
                                                            }}

                                                        />
                                                    }
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                        {freteGratis.ative && e?.quantidade >= freteGratis.unidades ?
                                                            <Box className='freteGratis' sx={{
                                                                background: theme.palette.tertiary.main,
                                                                zIndex: 100,
                                                                padding: 1
                                                            }}>
                                                                <Typography sx={{ color: theme.palette.text.inverse, fontWeight: 'bold' }}>FRETE GRÁTIS</Typography>
                                                            </Box>
                                                            : null}
                                                        <Typography fontWeight={'bold'} color={'#fff'}>
                                                            {` ${e?.quantidade} unidades`}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <img
                                                    className={product?.data?.activeItem?.id === e?.id ? 'vitLovCart' : null}
                                                    src={e?.image}
                                                    style={{
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height: '200px',
                                                        borderRadius: '4px',
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.background.default,
                                                        padding: '10px',
                                                        borderRadius: '0 0 8px 8px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        <Typography fontWeight={'bold'} color={theme.palette.text.primary}>
                                                            Preço:
                                                        </Typography>
                                                        <Typography color={theme.palette.text.primary}>
                                                            {'R$' + e?.preco}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        <Typography fontWeight={'bold'} color={theme.palette.text.primary}>
                                                            Preço unidade:
                                                        </Typography>
                                                        <Typography color={theme.palette.text.primary}>
                                                            {'R$' + (e?.preco / e?.quantidade).toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                    {newProduto.length > 0 && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Typography
                                                                fontWeight={'bold'}
                                                                color={e?.quantidade === 1 ? 'transparent' : theme.palette.text.primary}
                                                            >
                                                                Economia:
                                                            </Typography>
                                                            <Typography
                                                                fontSize={20}
                                                                fontWeight={'bold'}
                                                                color={e?.quantidade === 1 ? 'transparent' : theme.palette.secondary.main}
                                                            >
                                                                {` ${Math.abs((((e?.preco / e?.quantidade) - newProduto[0].preco) / newProduto[0].preco) * 100).toFixed(2) + '%'}`}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Typography color={theme.palette.text.primary} fontWeight={'bold'}>
                                                        {'Em até 12x de R$' + getPrecoParcela(e?.preco)}
                                                    </Typography>
                                                    {home ?
                                                        <Button sx={[buttonStyle, { padding: 1, '&:hover': { background: theme.palette.secondary.rgba } }]}
                                                            component={Link}
                                                            to={'/carrinho?home=true'}
                                                            onClick={() => {
                                                                const newProduto = { ...product.data }
                                                                newProduto.activeItem = e
                                                                product.updateProduct(newProduto)
                                                            }}
                                                        >
                                                            <Typography fontSize={20} fontWeight={'700'} sx={{ color: theme.palette.background.default, textTransform: 'none', '&:hover': { color: theme.palette.text.primary } }}>Comprar agora</Typography>
                                                        </Button>
                                                        : null}
                                                </Box>
                                            </Box>
                                        </Button>

                                    </SwiperSlide>
                                )
                            })}
                        </Box>
                    )
                }, [product])}
            </Swiper >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {controler.slides.map((e, idx) => {
                    return (
                        <Button
                            key={idx}
                            sx={{

                            }}
                            onClick={() => {
                                swiperRef.current.swiper.slideTo(idx)
                            }}
                        >
                            <Box sx={{ width: 15, height: 15, backgroundColor: idx === controler.activeItem ? theme.palette.secondary.main : theme.palette.background.paper }}>

                            </Box>
                        </Button>
                    )
                })}
            </Box>
        </Box >
    )
}