import { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from '../functions';
import mainApi from '../apis/mainApi';


export const Product = createContext()

export default props => {
    const cookies = new Cookies()
    const [data, setData] = useState(null)
    async function getData() {
        function FCallback(callback) {
            if (callback.status === 200) {
                const getCart = cookies.get('cart')
                if (getCart) {
                    callback.apiReturn.apiReturn.activeItem = getCart
                }
                setData(callback?.apiReturn?.apiReturn)
                return
            }
            setData(false)
        }
        await mainApi('initialApplication', 'GET', null, FCallback)
    }

    useEffect(() => {
        getData()
    }, [])

    function deleteActiveItem() {
        const newData = { ...data }
        newData.activeItem = {}
        setData(newData)
    }
    function updateProduct(data, FCallback) {
        cookies.set('cart', data.activeItem)
        setData(data)
        if (FCallback) {
            FCallback()
        }
    }


    return (
        <Product.Provider value={{ data, updateProduct, deleteActiveItem }}>
            {props.children}
        </Product.Provider>
    )
}
