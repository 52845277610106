import React, { useState, useContext, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Collapse, ListItemIcon, ListItemButton, ListItemText, Typography, TextField, ButtonGroup, Button } from '@mui/material';
import useWindowDimensions from "../../components/useWindowDimensions";
import mainApi from "../../apis/mainApi";
import { TestaEMAIL } from "../../components/validaCampo";
import { SnackBarAlert, SnackBarSucess } from "../../components/snackbar";

export default props => {
    document.title = 'Herpscalm'
    const theme = useTheme()
    const screenHeight = useWindowDimensions().height
    const screenWidth = useWindowDimensions().width
    const [email, setEmail] = useState({
        value: '',
        error: false
    })
    const [cadastrado, setCadastrado] = useState({
        controller: false,
        status: 0
    })
    const snackBarInitial = {
        id: 1,
        open: false,
        message: ""
    }
    const [snackBar, setSnackBar] = useState(snackBarInitial)
    function closeSnack() {
        setSnackBar(snackBarInitial)
    }
    /*
    #3D3C3C
    #565656
    #F17087
    */
    return (
        <Box sx={{
            backgroundColor: theme.palette.background.paper, flexGrow: 1}} height={'auto'}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row'
                },
            }}>
                <Box className={'construcao-left'} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 350,
                    [theme.breakpoints.up('md')]: {
                        height: screenHeight - 50,
                        width: '50%'
                    },
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img className="vitLovConstrucao" src="../assets/images/construcao/vitLov.png" width={310} />
                    </Box>
                </Box>
                <Box sx={{
                    width: '100%',
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    [theme.breakpoints.up('md')]: {
                        height: screenHeight - 50,
                        width: '50%',
                        padding: 15
                    },
                }}>
                    <Box sx={{
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: 10,
                            paddingRight: 10,
                        },
                    }}>
                        <Typography variant="h2" fontWeight={'bold'} color={'#fff'} textAlign={'center'}>Insira seu E-mail e acompanhe o lançamento do produto</Typography>
                    </Box>
                    {cadastrado.controller === true && cadastrado.status === 1 ?
                        <Typography variant="h5" fontWeight={'bold'} color={'#fff'} textAlign={'center'}>E-mail cadastrado com sucesso</Typography>
                        :
                        cadastrado.controller === true && cadastrado.status === 2 ?
                            <Typography variant="h5" fontWeight={'bold'} color={'#fff'} textAlign={'center'}>E-mail previamente cadastrado</Typography>
                            :
                            <Box display={'flex'} justifyContent={'center'} width={'80%'} gap={2} >
                                <TextField
                                    value={email.value}
                                    onChange={(value) => {
                                        const x = { ...email }
                                        x.value = value.target.value
                                        setEmail(x)
                                    }}
                                    onBlur={(value) => {
                                        const x = { ...email }
                                        if (TestaEMAIL(value.target.value) === false) {
                                            x.error = true
                                            setEmail(x)
                                        }
                                        else {
                                            x.error = false
                                        }
                                        setEmail(x)
                                    }}
                                    error={email.error}
                                    label="Insira o seu e-mail aqui*" sx={{ backgroundColor: 'rgba(217,217,217,0.2)', flex: 1 }} InputLabelProps={{
                                        style: {
                                            color: '#fff',
                                        }
                                    }} />
                                <Button sx={{ backgroundColor: '#F17087' }}
                                    onClick={async () => {
                                        if (TestaEMAIL(email.value) === false) {
                                            const y = { ...email }
                                            y.error = true
                                            setEmail(y)
                                            const x = { ...snackBar }
                                            x.message = 'E-mail inválido'
                                            x.open = true
                                            setSnackBar(x)
                                            return
                                        }
                                        function FCallback(callback) {
                                            if (callback.status !== 200) {
                                                const x = { ...snackBar }
                                                x.message = callback.apiReturn.message ? callback.apiReturn?.message : 'Não foi possível fazer o cadastro'
                                                x.open = true
                                                setSnackBar(x)
                                                const newCadastro = { ...cadastrado }
                                                newCadastro.status = 2
                                                newCadastro.controller = true
                                                setCadastrado(newCadastro)
                                                return
                                            }
                                            const newCadastro = { ...cadastrado }
                                            newCadastro.status = 1
                                            newCadastro.controller = true
                                            setCadastrado(newCadastro)
                                            const x = { ...snackBar }
                                            x.message = 'Cadastro realizado com sucesso'
                                            x.open = true
                                            x.id = 2
                                            setSnackBar(x)
                                        }
                                        await mainApi('leads/register', 'POST', { email: email.value }, FCallback)
                                    }}
                                >
                                    <Typography variant="h5" color={'#fff'} fontSize={12}>Participar</Typography>
                                </Button>
                            </Box>
                    }
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button href="https://www.facebook.com/vitlovoficial" target="_blank" to="/url">
                            <img src="../assets/images/construcao/facebook.png" width={80} />
                        </Button>
                        <Button href="https://www.instagram.com/vitlovoficial" target="_blank" to="/url">
                            <img src="../assets/images/construcao/instagram.png" width={80} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            <SnackBarAlert ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <SnackBarSucess ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
        </Box>
    )
}