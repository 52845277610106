import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { color, typography } from "@mui/system";

//color design tokens

// mui theme settings

export const themeSettings = (mode) => {
    return {
        palette: {
            logo: '../assets/images/logos/logo.png',
            mode: mode,
            ...(
                {
                    primary: {
                        main: '#23B2E3',
                        rgba: 'rgba(35,178,227,0.3)'
                    },
                    secondary: {
                        main: '#286FD7',
                        rgba: 'rgba(40,111,215,0.3)'
                    },
                    tertiary: {
                        main: '#FB9600',
                        rgba: 'rgba(251,150,0)'
                    },
                    background: {
                        default: '#fff',
                        paper: '#F1F1F1',
                    },
                    warning: {
                        main: '#ed6c02',
                        light: '#ff9800',
                        dark: '#e65100'
                    },
                    sucess: {
                        main: '#2e7d32',
                        light: '#4caf50',
                        dark: '#1b5e20',
                    },
                    error: {
                        main: '#FF7A00',
                        light: '#e57373',
                        dark: '#d32f2f'
                    },
                    text: {
                        primary: '#000',
                        secondary: 'rgba(0, 0, 0, 0.7)',
                        disabled: 'rgba(0, 0, 0, 0.5)',
                        inverse: 'rgba(255, 255, 255, 0.87)'
                    }
                }
            )
        },
        typography: {
            fontFamily: ["Public Sans", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 14,
            }
        }
    }
}

// context

export const ColorModeContext = createContext({
    toggleColorMode: () => {

    }
})

export const useMode = () => {
    const [mode, setMode] = useState("light")
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => setMode((prev) => (prev === 'dark' ? 'light' : 'dark'))
        })
    )

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

    return [theme, colorMode]
}