import { Box, Button, Typography, useTheme, useMediaQuery, Grow } from "@mui/material";
import { ButtonComprar } from "../../../components/ButtonComprar";
import { useInView } from 'react-intersection-observer';


export function Section3({ }) {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const tytleStyle = {
        fontWeight: 'bold', fontSize: isSmallScreen ? 30 : 40, textAlign: 'center', color: theme.palette.text.primary
    }
    const subtitleStyle = {
        fontSize: isSmallScreen ? 16 : 20,
        color: theme.palette.text.primary,
        textAlign: 'center',
        maxWidth: 900
    }

    const objTitle = {
        fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: theme.palette.text.primary
    }
    const objSubtitle = {
        fontSize: 16, textAlign: 'center', color: theme.palette.text.primary
    }
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    })
    const scrollToTarget = () => {
        const target = document.getElementById('Combos');
        if (target) {
            target.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        }
    };
    return (
        <Grow ref={ref} in={inView} timeout={1000}>
            <Box id={'inicio'} sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1, padding: isSmallScreen ? 2 : 10, paddingTop: 2, paddingBottom: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography sx={tytleStyle}>COMPRAR HERPSCALM É SIMPLES E SEGURO</Typography>
                    <Typography sx={subtitleStyle}>Veja por que você pode confiar em nossa loja.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', marginTop: 5, flexWrap: 'wrap', justifyContent: 'space-between', rowGap: 5 }}>
                    {data.map((e, idx) => {
                        const typeFlex = isSmallScreen ? 'center' : idx % 2 ? 'flex-end' : 'flex-start'
                        return (
                            <Box sx={{ width: isSmallScreen ? '100%' : '45%', display: 'flex', justifyContent: typeFlex }}>
                                <Box sx={{ display: 'flex', gap: 2, width: '70%' }} >
                                    <Box>
                                        <img src={e.image} />
                                    </Box>
                                    <Box>
                                        <Typography sx={objTitle}>{e.title}</Typography>
                                        <Typography sx={objSubtitle}>{e.subtitle}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, marginTop: 5 }}>
                    <Typography sx={subtitleStyle}>Faça sua compra com <Typography component={'span'} sx={[subtitleStyle, { fontWeight: 'bold' }]}>TRANQUILIDADE</Typography> e <Typography component={'span'} sx={[subtitleStyle, { fontWeight: 'bold' }]}>SEGURANÇA.</Typography></Typography>
                    <ButtonComprar onClick={scrollToTarget} />
                </Box>
            </Box>
        </Grow>
    )
}


{/* <Box>
    <ButtonComprar sx={{ background: theme.palette.tertiary.main, color: '#fff', fontWeight: 'bold' }} />
</Box> */}

const data = [
    {
        title: 'Tecnologia SSL',
        subtitle: 'Protegemos suas informações com a mais alta segurança.',
        image: "../assets/images/home/section3/ssl.png"
    },
    {
        title: 'Política de devolução',
        subtitle: 'Garantimos todos os direitos previstos no Código de Defesa do Consumidor.',
        image: "../assets/images/home/section3/devolucao.png"
    },
    {
        title: 'Entrega Rápida',
        subtitle: 'Utilizamos a versatilidade e eficácia dos correios para entregar sua compra.',
        image: "../assets/images/home/section3/entrega.png"
    },
    {
        title: 'Suporte dedicado',
        subtitle: 'Atendimento dedicado para qualquer dúvida ou necessidade.',
        image: "../assets/images/home/section3/suporte.png"
    },
]