import { useState, useContext } from "react";
import { Box, Button, Typography, useTheme, useMediaQuery, Grow, Collapse } from "@mui/material";
import { ButtonComprar } from "../../../components/ButtonComprar";
import { useInView } from 'react-intersection-observer';
import { Product } from "../../../contexts/product";


export function Section4({ }) {
    const theme = useTheme()
    const produto = useContext(Product)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [colAtivo, setColAtivo] = useState(null)
    const tytleStyle = {
        fontWeight: 'bold', fontSize: isSmallScreen ? 30 : 40, textAlign: 'center', color: theme.palette.text.primary
    }
    const subtitleStyle = {
        fontSize: isSmallScreen ? 16 : 20,
        color: theme.palette.text.primary,
        textAlign: 'center',
        maxWidth: 900
    }

    const objTitle = {
        fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: theme.palette.text.primary
    }
    const objSubtitle = {
        fontSize: 16, textAlign: 'center', color: theme.palette.text.primary
    }
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    })
    function handleCol(idx) {
        setColAtivo(idx === colAtivo ? null : idx)
    }
    return (
        <Grow ref={ref} in={inView} timeout={1000}>
            <Box id={'inicio'} sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1, padding: isSmallScreen ? 2 : 10, paddingTop: 2, paddingBottom: 10 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography sx={tytleStyle}>PERGUNTAS FREQUENTES</Typography>
                    <Typography sx={subtitleStyle}>Caso ainda não tenha se decidido em comprar HERPSCALM, aqui estão algumas dúvidas e respostas para você optar pela compra.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', marginTop: 5, flexWrap: 'wrap', justifyContent: 'space-between', rowGap: 5 }}>
                    {produto?.data?.perguntas_respostas.map((e, idx) => {
                        return (
                            <Box sx={{ width: isSmallScreen ? '100%' : '49%' }}>
                                <Button onClick={() => handleCol(idx)} sx={{ width: '100%', padding: 0, textTransform: 'none' }}>
                                    <Box sx={{ background: theme.palette.secondary.main, padding: 2, width: '100%' }}>
                                        <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#fff' }}>{e?.pergunta}</Typography>
                                    </Box>
                                </Button>
                                <Collapse in={colAtivo === idx}>
                                    <Box sx={{ background: theme.palette.background.paper, padding: 3 }}>
                                        <Typography sx={[objSubtitle, { color: theme.palette.text.primary }]}>{e?.resposta}</Typography>
                                    </Box>
                                </Collapse>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Grow>
    )
}


{/* <Box>
    <ButtonComprar sx={{ background: theme.palette.tertiary.main, color: '#fff', fontWeight: 'bold' }} />
</Box> */}

const data = [
    {
        pergunta: "Como Herpscalm funciona?",
        resposta: "Herpscalm inibe a replicação do vírus da herpes e fortalece seu sistema imunológico."
    },
    {
        pergunta: "Existem efeitos colaterais?",
        resposta: "Herpscalm é geralmente bem tolerado. Se sentir algum desconforto, interrompa o uso e consulte um médico."
    },
    {
        pergunta: "Quando verei os resultados?",
        resposta: "Muitos usuários notam melhorias em poucas semanas. Para melhores resultados, use de forma contínua por 6 meses."
    },
    {
        pergunta: "Quem pode usar Herpscalm?",
        resposta: "Herpscalm é indicado para adultos que sofrem de herpes recorrente ou desejam prevenir surtos."
    },
    {
        pergunta: "Como comprar?",
        resposta: "Clique em 'Comprar Agora', escolha seu pacote e finalize a compra de forma rápida e segura."
    }
]