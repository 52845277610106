import { Box, Button, Typography, useTheme, useMediaQuery, Grow } from "@mui/material";
import { ButtonComprar } from "../../../components/ButtonComprar";
import { useInView } from 'react-intersection-observer';


export function Section2({ }) {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const tytleStyle = {
        fontWeight: 'bold', fontSize: isSmallScreen ? 30 : 40, textAlign: 'center', color: theme.palette.text.primary
    }
    const subtitleStyle = {
        fontSize: isSmallScreen ? 16 : 20,
        color: theme.palette.text.primary,
        textAlign: 'center',
        maxWidth: 900
    }
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    })

    const objTitle = {
        fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: theme.palette.text.primary
    }
    const objSubtitle = {
        fontSize: 16, textAlign: 'center', color: theme.palette.text.primary
    }
    const scrollToTarget = () => {
        const target = document.getElementById('Combos');
        if (target) {
            target.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        }
    };
    return (
        <Grow in={inView} timeout={1000}>
            <Box ref={ref} id={'inicio'} sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1, padding: isSmallScreen ? 2 : 10, paddingTop: 2, paddingBottom: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography sx={tytleStyle}>A CIÊNCIA POR TRÁS DE HERPSCALM</Typography>
                    <Typography sx={subtitleStyle}>Cada ingrediente em Herpscalm foi escolhido com base em estudos científicos rigorosos.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', marginTop: 5, gap: isSmallScreen ? 5 : 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: isSmallScreen ? 'flex-start' : 'center', gap: 5 }}>
                        {leftData.map((e) => (
                            <Box sx={{ display: 'flex', gap: 2, flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
                                <Box>
                                    <Typography sx={[objTitle, { textAlign: isSmallScreen ? 'center' : 'right' }]}>{e.title}</Typography>
                                    <Typography sx={[objSubtitle, { textAlign: isSmallScreen ? 'center' : 'right' }]}>{e.subtitle}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img alt={e.title} src={e.image} style={{ objectFit: 'initial', width: 'auto', height: 'auto' }} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    {isSmallScreen ? null :
                        <img alt={'Herpscalm'} src="../assets/images/home/section2/herpscalm.png" style={{ maxHeight: 300 }} />
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: isSmallScreen ? 'flex-start' : 'center', gap: 5 }}>
                        {rigthData.map((e) => (
                            <Box sx={{ display: 'flex', gap: 2, flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
                                {isSmallScreen ? null :
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img alt={e.title} src={e.image} />
                                    </Box>
                                }
                                <Box>
                                    <Typography sx={[objTitle, { textAlign: isSmallScreen ? 'center' : 'left' }]}>{e.title}</Typography>
                                    <Typography sx={[objSubtitle, { textAlign: isSmallScreen ? 'center' : 'left' }]}>{e.subtitle}</Typography>
                                </Box>
                                {isSmallScreen ?
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img alt={e.title} src={e.image} />
                                    </Box> : null
                                }
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                    <Typography sx={subtitleStyle}>Confie na ciência e sinta a diferença com <Typography component={'span'} sx={[subtitleStyle, { color: theme.palette.primary.main, fontWeight: 'bold' }]}>HERPSCALM.</Typography></Typography>
                    <ButtonComprar onClick={scrollToTarget} />
                </Box>
            </Box>
        </Grow>
    )
}


{/* <Box>
    <ButtonComprar sx={{ background: theme.palette.tertiary.main, color: '#fff', fontWeight: 'bold' }} />
</Box> */}

const leftData = [
    {
        title: 'LISINA',
        subtitle: 'Estudos mostram que a Lisina pode reduzir a frequência e a gravidade dos surtos de herpes.',
        image: "../assets/images/home/section2/lisina.png"
    },
    {
        title: 'Zinco',
        subtitle: 'Demonstrou acelerar a cicatrização e fortalecer a resposta imune.',
        image: "../assets/images/home/section2/zinco.png"
    },
]
const rigthData = [
    {
        title: 'VITAMINA C',
        subtitle: 'Conhecida por suas propriedades imunológicas, a Vitamina C ajuda a manter seu corpo resistente.',
        image: "../assets/images/home/section2/vitaminac.png"
    },
    {
        title: 'VITAMINA B6',
        subtitle: 'Essencial para o funcionamento do sistema nervoso e no controle da herpes.',
        image: "../assets/images/home/section2/vitaminab6.png"
    },
]