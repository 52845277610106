import React from "react";
import { useTheme } from '@mui/material/styles';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Divider,
    Badge,
    Button,
    Typography,
    Drawer,
    useMediaQuery
} from '@mui/material';
import { User } from "../contexts/user";
import { Product } from "../contexts/product";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Fade from '@mui/material/Fade';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { Link } from "react-router-dom";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import useWindowDimensions from "./useWindowDimensions";
import MenuIcon from '@mui/icons-material/Menu';

export const Header = (props) => {
    const theme = useTheme();
    const user = React.useContext(User);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const product = React.useContext(Product);
    const [menuOpen, setMenuOpen] = React.useState({
        open: false,
        anchor: 0
    });
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleMenuToggle = (event) => {
        setMenuOpen({
            open: !menuOpen.open,
            anchor: event.currentTarget
        });
    };

    const handleMenuClose = () => {
        setMenuOpen({
            open: false,
            anchor: null
        });
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Box sx={{
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            padding: 2
        }}>
            <Box sx={{
                maxWidth: 1400,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                {isMobile && (
                    <IconButton onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                )}
                <Button
                    disableTouchRipple
                    disableFocusRipple
                    component={Link}
                    to='/'
                    sx={{
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <img
                        src="../assets/images/logos/logo.png"
                        style={{
                            position: 'relative',
                            objectFit: 'contain',
                            bottom: 5
                        }}
                    />
                </Button>
                {!props.manutencao && !isMobile && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        gap: 5,
                        width: '100%'
                    }}>
                        {['Início', 'Componentes', 'Compra Segura', 'Combos', 'Dúvidas'].map((e) => {
                            const link = e === 'Compra Segura' ? 'CompraSegura' : e === 'Dúvidas' ? 'Duvidas' : e === 'Início' ? 'Inicio' : e
                            return (
                                <Box key={e} component={Link}
                                    to={`/#${link}`} sx={{ textDecoration: 'none' }}>
                                    <Typography sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 18
                                    }}>
                                        {e}
                                    </Typography>
                                </Box>
                            )
                        }
                        )}
                    </Box>
                )}
                {!props.manutencao && (
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <IconButton
                            aria-label="cart"
                            sx={{ position: 'relative', top: 3 }}
                            component={Link}
                            to='carrinho'
                        >
                            <Badge
                                badgeContent={product?.data?.activeItem?.quantidade ? product?.data?.activeItem?.quantidade : '0'}
                                color="secondary"
                            >
                                <ShoppingCartIcon
                                    sx={{
                                        fontSize: 25,
                                        color: theme.palette.text.primary
                                    }}
                                />
                            </Badge>
                        </IconButton>
                        <IconButton onClick={handleMenuToggle}>
                            <AccountCircleIcon
                                sx={{
                                    fontSize: 35,
                                    color: theme.palette.text.primary
                                }}
                            />
                        </IconButton>
                        <Menu
                            open={menuOpen.open}
                            anchorEl={menuOpen.anchor}
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            TransitionComponent={Fade}
                        >
                            {['', undefined, null].includes(user?.data?.token) ? (
                                <MenuItem
                                    component={Link}
                                    to='minha-conta'
                                    onClick={handleMenuClose}
                                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                >
                                    <LoginIcon />
                                    Entrar
                                </MenuItem>
                            ) : (
                                <>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: theme.palette.background.default,
                                        padding: 1
                                    }}>
                                        <AccountBoxIcon sx={{ fontSize: 30 }} />
                                        <Typography>{user.data.nome}</Typography>
                                    </Box>
                                    <Divider />
                                    <MenuItem
                                        component={Link}
                                        to='minha-conta?t=0'
                                        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                        onClick={handleMenuClose}
                                    >
                                        <ContactPageIcon />
                                        Meus Dados
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        component={Link}
                                        to='minha-conta?t=1'
                                        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                        onClick={handleMenuClose}
                                    >
                                        <ShoppingCartIcon />
                                        Pedidos
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                        onClick={() => {
                                            user.deleteData();
                                            product.deleteActiveItem();
                                            handleMenuClose();
                                        }}
                                    >
                                        <LogoutIcon />
                                        Sair
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </Box>
                )}
            </Box>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
            >
                <Box sx={{
                    width: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2
                }}>
                    {['Início', 'Componentes', 'Compra Segura', 'Combos', 'Dúvidas'].map((e) => {
                        const link = e === 'Compra Segura' ? 'CompraSegura' : e === 'Dúvidas' ? 'Duvidas' : e === 'Início' ? 'Inicio' : e
                        return (
                            <Box key={e} component={Link}
                                to={`/#${link}`} sx={{ marginBottom: 2, textDecoration: 'none' }}>
                                <Typography sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: 20
                                }}>
                                    {e}
                                </Typography>
                            </Box>
                        )
                    }
                    )}
                </Box>
            </Drawer>
        </Box>
    );
}
