import React, { useState, useContext, useRef, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Avatar, List, Grid, ListItem, Collapse } from '@mui/material';
import useWindowDimensions from "../../components/useWindowDimensions";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { Product } from "../../contexts/product";
import { ModalDepoimento } from "../../components/modalDepointo";

import { Pixel } from "../../components/pixel";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import 'swiper/swiper-bundle.css';
import ListProducts from "../../components/listProducts";
import { Section1 } from "./components/section1";
import { Section2 } from "./components/section2";
import { Section3 } from "./components/section3";
import { SectionCombos } from "./components/sectionCombos";
import { Section4 } from "./components/section4";

export default props => {
    const theme = useTheme()
    const produto = useContext(Product)
    const depoimentoInitial = {
        data: null,
        active: false
    }
    const [depoimento, setDepoimento] = useState(depoimentoInitial)
    document.title = 'Herpscalm - Suplemento alimentar'
    const params = {
        loop: true,
        autoplay: {
            delay: 6000, // tempo de transição entre os slides em milissegundos
            disableOnInteraction: true
        },
        modules: [Pagination, Navigation, Autoplay, EffectFade],
        effect: 'fade',
        pagination: {
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '" style="background-color: ' + theme.palette.secondary.main + ';"></span>'; // Altere "red" para a cor desejada
            },
        },
    };
    const scrollToTarget = (id) => {
        const target = document.getElementById(id);
        if (target) {
            target.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash) {
                const hash = window.location.hash.substring(1);
                scrollToTarget(hash);
            }
        };
        window.addEventListener('hashchange', handleHashChange);
        handleHashChange();
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [window.location.hash]);
    Pixel()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', backgroundColor: theme.palette.background.default, width: '100%', gap: 10 }}>
            <Box sx={{ position: 'absolute', top: 0, left: 0, background: 'transparent', backgroundImage: 'url("../assets/images/home/section1/bg.png")', height: 609, width: '100%', zIndex: 1 }} />
            <Box id={'Inicio'} sx={{ display: 'flex', maxWidth: 1400, width: '100%', zIndex: 2 }}>
                <Section1 />
            </Box>
            <Box id={'Componentes'} sx={{ display: 'flex', maxWidth: 1400, width: '100%', zIndex: 2 }}>
                <Section2 />
            </Box>
            <Box id={'CompraSegura'} sx={{ display: 'flex', maxWidth: 1400, width: '100%', zIndex: 2 }}>
                <Section3 />
            </Box>
            <Box id={'Combos'} sx={{ display: 'flex', maxWidth: 1400, width: '100%', zIndex: 2 }}>
                <SectionCombos />
            </Box>
            {produto?.data?.perguntas_respostas ?
                <Box id={'Duvidas'} sx={{ display: 'flex', maxWidth: 1400, width: '100%', zIndex: 2 }}>
                    <Section4 />
                </Box>
                : null}
        </Box >
    )
}