import React, { useContext, useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, TextField, ButtonGroup } from '@mui/material';
import { ModalLoading } from "../../components/modal";
import mainApi from "../../apis/mainApi";
import StatusScreen from "../../components/statusScreen";
import { ModalInfo } from "../../components/modalInfo";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../components/useWindowDimensions";
import { cpfMask, celularMask } from "../../components/masks";
import { insertClientes, loginClientes } from "../../apis/clientes";
import { TestaCPF, TestaEMAIL, TestaCELULAR } from "../../components/validaCampo";
import { SnackBarAlert } from "../../components/snackbar";
import { User } from "../../contexts/user";
import { decrypt } from "../../functions";
import { Link } from "react-router-dom";



export default props => {
    const theme = useTheme();
    const screenHeight = useWindowDimensions().height
    const [modalAtive, setModalAtive] = useState(true)
    const [redefinir, setRedefinir] = useState({
        open: false,
        token: null,
    })
    const [snackBar, setSnackBar] = useState({
        id: 1,
        open: false,
        message: ""
    })
    const errorInitual = {
        cpf: false,
        email: false,
        senha: false,
        confirmSenha: false,
        celular: false,
        primeiro_nome: false,
        sobrenome: false
    }
    const [error, setError] = useState(errorInitual)
    const [registerControll, setRegisterControll] = useState(1)
    function closeSnack() {
        const x = { ...snackBar }
        x.open = false
        setSnackBar(x)
    }
    function closeModal() {
        setModalAtive(false)
    }
    const user = useContext(User)
    const modalInfoInitial = {
        open: false,
        body: (
            <Box>
                <Typography>teste</Typography>
            </Box>)
    }
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    const [statusScreen, setStatusScreen] = useState({
        active: false,
        id: null,
        paymentMethod: null,
        serverId: null,
        dateCreated: null
    })
    const useQuery = () => new URLSearchParams(useLocation().search);
    const [leftOpen, setLeftOpen] = useState(false)
    const [form, setForm] = useState({
        cpf: '',
        primeiro_nome: '',
        sobrenome: '',
        email: '',
        senha: '',
        confirmSenha: '',
        ddd: '',
        celular: '',
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        complemento: '',
        salvarEndereco: false,
        entrega: {
            id: null,
            name: '',
            preco: null,
            prazo: null,
            load: false
        },
        pagamento: {
            inicialization: null,
            customization: null,
            open: false
        }
    })
    const [activeScreen, setActiveScreen] = useState(0)
    const [screens, setScreens] = useState([])
    function updateScreen(idx) {
        setActiveScreen(idx)
    }
    function changeLeftOpen(value) {
        setLeftOpen(value)
    }
    async function getData() {
        function FCallback(callback) {
            setModalAtive(false)
            if (callback.status === 200) {
                if (Array.isArray(callback?.apiReturn?.apiReturn)) {
                    setScreens(callback.apiReturn.apiReturn)
                }
            }
        }
        await mainApi('minha_conta_modulos', 'GET', null, FCallback)
    }
    function updateStatusScreen(statusScreen, form) {
        setForm(form)
        setStatusScreen(statusScreen)
    }
    function updateModal(value) {
        setModalAtive(value)
    }
    function closeModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    function updateModalInfo(value) {
        setModalInfo(value)
    }
    function desactiveStatusScreen() {
        const x = { ...statusScreen }
        x.active = false
        window.statusScreenBrickController.unmount()
        setStatusScreen(x)
    }

    const query = useQuery();
    var x = query.getAll('t')
    useEffect(() => {
        var PRedefinicao = query.getAll('r')
        var cartRedefinition = query.getAll('c')
        if (![undefined, null, ''].includes(PRedefinicao[0])) {
            var PRedefinicao = PRedefinicao[0].split(' ').join('+')
            var PRedefinicao = decrypt(PRedefinicao)
            const newRedefinir = { ...redefinir }
            newRedefinir.token = PRedefinicao
            setRedefinir(newRedefinir)
            //setData(x)
        }
        if (![undefined, null, ''].includes(x[0])) {
            setActiveScreen(parseInt(x[0]))
        }
        getData()
    }, [window.location.href])
    return (
        <Box sx={{
            height: '93%', backgroundColor: theme.palette.background.paper, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 5, flexDirection: 'column', [theme.breakpoints.down('md')]: {
                height: '100%'
            },
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: '95%',
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                    width: '70%'
                },
            }}>
                <Box sx={{
                    width: '100%',
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.background.default,
                    [theme.breakpoints.up('md')]: {
                        height: screenHeight - 150,
                        width: '100%',
                        padding: 15
                    },
                }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', rowGap: 2, minHeight: 450, alignItems: 'center', justifyContent: 'flex-start' }}>
                        <img src="../assets/images/logos/logo.png" width={190} style={{ objectFit: 'contain' }} />
                        {redefinir.token || redefinir.open ?
                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>REDEFINIR SENHA</Typography>
                            :
                            <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ alignSelf: 'center', gap: 1 }}>
                                <Button onClick={() => setRegisterControll(1)} sx={{ padding: 1, borderRadius: 0, borderRightColor: theme.palette.secondary.main + ' !important', borderColor: theme.palette.secondary.main, backgroundColor: registerControll === 1 ? theme.palette.secondary.main : null, color: registerControll === 1 ? theme.palette.text.primary : theme.palette.secondary.main, '&:hover': { borderColor: theme.palette.secondary.main, color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.rgba } }}>Entrar</Button>
                                <Button onClick={() => setRegisterControll(2)} sx={{ padding: 1, borderRadius: 0, backgroundColor: registerControll === 2 ? theme.palette.primary.main : null, color: registerControll === 2 ? theme.palette.text.primary : null, '&:hover': { color: theme.palette.text.primary } }}>Registrar</Button>
                            </ButtonGroup>
                        }
                        {
                            redefinir.token ?
                                <Box display={'flex'} flexDirection={'column'} gap={2} sx={{
                                    [theme.breakpoints.down('md')]: {
                                        flexDirection: 'column'
                                    },
                                }}>
                                    <TextField
                                        fullWidth
                                        margin="none"
                                        required
                                        name="password"
                                        label="Senha"
                                        type="password"
                                        error={error.senha}
                                        value={form.senha}
                                        id="password"
                                        onChange={(value) => {
                                            const x = { ...form }
                                            x.senha = value.target.value
                                            setForm(x)
                                        }}
                                        onBlur={(value) => {
                                            const x = { ...error }
                                            x.senha = value.target.value === '' ? true : false
                                            setError(x)
                                            if (value.target.value === '') {
                                                setTimeout(() => {
                                                    const y = { ...snackBar }
                                                    y.open = true
                                                    y.id = 1
                                                    y.message = 'Senha não pode ser vazia'
                                                    setSnackBar(y)
                                                }, 500)
                                            }
                                        }}
                                        autoComplete="current-password"
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee',
                                                borderRadius: 0,
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 0
                                            }
                                        }}
                                        sx={{
                                            maxWidth: 300, borderRadius: 0, "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: theme.palette.secondary.main
                                                }
                                            }
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        margin="none"
                                        required
                                        name="confirm-password"
                                        label="Confirmar Senha"
                                        type="password"
                                        error={error.confirmSenha}
                                        value={form.confirmSenha}
                                        id="confirm-password"
                                        onChange={(value) => {
                                            const x = { ...form }
                                            x.confirmSenha = value.target.value
                                            setForm(x)
                                        }}
                                        onBlur={(value) => {
                                            const x = { ...error }
                                            x.confirmSenha = value.target.value === '' || value.target.value !== form.senha ? true : false
                                            setError(x)
                                            if (value.target.value === '' || value.target.value !== form.senha) {
                                                setTimeout(() => {
                                                    const y = { ...snackBar }
                                                    y.open = true
                                                    y.id = 1
                                                    y.message = 'As senhas devem ser iguais'
                                                    setSnackBar(y)
                                                }, 500)
                                            }
                                        }}
                                        autoComplete="current-password"
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee',
                                                borderRadius: 0,
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 0
                                            }
                                        }}
                                        sx={{
                                            maxWidth: 300, borderRadius: 0, "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: theme.palette.secondary.main
                                                }
                                            }
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ maxWidth: 300, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                        onClick={async () => {
                                            setModalAtive(true)
                                            if (error.senha || form.senha === '') {
                                                setModalAtive(false)
                                                const y = { ...snackBar }
                                                y.open = true
                                                y.id = 1
                                                y.message = 'Senha precisa ser preenchido'
                                                setSnackBar(y)
                                                return
                                            }
                                            if (error.confirmSenha || form.confirmSenha === '') {
                                                setModalAtive(false)
                                                const y = { ...snackBar }
                                                y.open = true
                                                y.id = 1
                                                y.message = 'Confirmação da senha deve ser preenchida e igual a senha'
                                                setSnackBar(y)
                                                return
                                            }
                                            function Fcallback(callback) {
                                                setModalAtive(false)
                                                if (callback?.status !== 200) {
                                                    const x = { ...modalInfo }
                                                    x.open = true
                                                    x.body = (
                                                        <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                            <Typography fontWeight={'bold'} fontSize={20}>Olá!</Typography>
                                                            <Typography textAlign={'center'}>{callback?.apiReturn?.message}</Typography>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                                    <Button
                                                                        component={Link}
                                                                        to='/minha-conta'
                                                                        sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                        onClick={() => {
                                                                            const x = { ...form }
                                                                            x.senha = ''
                                                                            x.confirmSenha = ''
                                                                            setForm(x)
                                                                            setRedefinir({
                                                                                open: false,
                                                                                token: ''
                                                                            })
                                                                            closeModalInfo()
                                                                        }}
                                                                    >
                                                                        <Typography>Voltar</Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                    setModalInfo(x)
                                                    return
                                                }
                                                const x = { ...modalInfo }
                                                x.open = true
                                                x.body = (
                                                    <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                        <Typography fontWeight={'bold'} fontSize={20}>Olá!</Typography>
                                                        <Typography textAlign={'center'}>{callback?.apiReturn?.message}</Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                            <Box sx={{ display: 'flex', gap: 2 }}>
                                                                <Button
                                                                    component={Link}
                                                                    to='/minha-conta'
                                                                    sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                    onClick={() => {
                                                                        const x = { ...form }
                                                                        x.senha = ''
                                                                        x.confirmSenha = ''
                                                                        setForm(x)
                                                                        closeModalInfo()
                                                                        setRedefinir({
                                                                            open: false,
                                                                            token: ''
                                                                        })
                                                                    }}
                                                                >
                                                                    <Typography>Voltar</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                                setModalInfo(x)
                                            }
                                            await mainApi('redefinirSenha/cliente/redefinicao', 'POST', { senha: form.senha, token: redefinir.token }, Fcallback)
                                        }}
                                    >Redefinir</Button>
                                    <Button disableTouchRipple disableFocusRipple sx={{
                                        display: 'flex', justifyContent: 'flex-end', '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                        onClick={() => {
                                            const newRedefinir = { ...redefinir }
                                            newRedefinir.open = false
                                            newRedefinir.token = ''
                                            setRedefinir(newRedefinir)
                                        }}
                                    >
                                        <Typography textAlign={'end'} fontWeight={'bold'} sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>Voltar</Typography>
                                    </Button>
                                </Box>
                                :

                                redefinir.open ?
                                    <Box display={'flex'} flexDirection={'column'} gap={2} sx={{
                                        [theme.breakpoints.down('md')]: {
                                            flexDirection: 'column'
                                        },
                                    }}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            required
                                            value={form.email}
                                            id="email"
                                            name="email"
                                            label="E-mail"
                                            error={error.email}
                                            onChange={(value) => {
                                                const x = { ...form }
                                                x.email = value.target.value
                                                setForm(x)
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: theme.palette.text.secondary,
                                                    borderColor: '#eee'
                                                }
                                            }}
                                            onBlur={(value) => {
                                                const x = { ...error }
                                                const TestEmail = TestaEMAIL(value.target.value)
                                                x.email = TestEmail === true ? false : true
                                                setError(x)
                                                if (TestEmail === false) {
                                                    setTimeout(() => {
                                                        const y = { ...snackBar }
                                                        y.open = true
                                                        y.id = 1
                                                        y.message = 'E-mail inválido'
                                                        setSnackBar(y)
                                                    }, 500)
                                                }
                                            }}
                                            autoComplete=""
                                            inputProps={{
                                                style: {
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: 0
                                                }
                                            }}
                                            sx={{
                                                maxWidth: 300, borderRadius: 0, "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: theme.palette.secondary.main
                                                    }
                                                }
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ maxWidth: 300, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                            onClick={async () => {
                                                setModalAtive(true)
                                                if (error.email) {
                                                    const y = { ...snackBar }
                                                    y.open = true
                                                    y.id = 1
                                                    y.message = 'Campo "E-mail" precisa ser preenchido e ser válido'
                                                    setSnackBar(y)
                                                    setModalAtive(false)
                                                    return
                                                }
                                                function Fcallback(callback) {
                                                    setModalAtive(false)
                                                    if (callback?.status !== 200) {
                                                        const x = { ...modalInfo }
                                                        x.open = true
                                                        x.body = (
                                                            <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                <Typography fontWeight={'bold'} fontSize={20}>Olá!</Typography>
                                                                <Typography textAlign={'center'}>{callback?.apiReturn?.message}</Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                                        <Button
                                                                            sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                            onClick={() => {
                                                                                closeModalInfo()
                                                                            }}
                                                                        >
                                                                            <Typography>Voltar</Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        )
                                                        setModalInfo(x)
                                                        return
                                                    }
                                                    const x = { ...modalInfo }
                                                    x.open = true
                                                    x.body = (
                                                        <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                            <Typography fontWeight={'bold'} fontSize={20}>Olá!</Typography>
                                                            <Typography textAlign={'center'}>{callback?.apiReturn?.message}</Typography>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                                    <Button
                                                                        sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                        onClick={() => {
                                                                            closeModalInfo()
                                                                        }}
                                                                    >
                                                                        <Typography>Voltar</Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                    setModalInfo(x)
                                                }
                                                await mainApi('redefinirSenha/cliente/email', 'POST', { email: form.email }, Fcallback)
                                            }}
                                        >Redefinir</Button>
                                        <Button disableTouchRipple disableFocusRipple sx={{
                                            display: 'flex', justifyContent: 'flex-end', '&:hover': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                            onClick={() => {
                                                const newRedefinir = { ...redefinir }
                                                newRedefinir.open = false
                                                newRedefinir.token = ''
                                                setRedefinir(newRedefinir)
                                            }}
                                        >
                                            <Typography textAlign={'end'} fontWeight={'bold'} sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>Voltar</Typography>
                                        </Button>
                                    </Box>
                                    :
                                    registerControll === 1 ?
                                        <Box display={'flex'} flexDirection={'column'} gap={2} sx={{
                                            [theme.breakpoints.down('md')]: {
                                                flexDirection: 'column'
                                            },
                                        }}>
                                            <TextField
                                                fullWidth
                                                margin="none"
                                                required
                                                value={form.email}
                                                id="email"
                                                name="email"
                                                label="E-mail"
                                                error={error.email}
                                                onChange={(value) => {
                                                    const x = { ...form }
                                                    x.email = value.target.value
                                                    setForm(x)
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: theme.palette.text.secondary,
                                                        borderColor: '#eee'
                                                    }
                                                }}
                                                onBlur={(value) => {
                                                    const x = { ...error }
                                                    const TestEmail = TestaEMAIL(value.target.value)
                                                    x.email = TestEmail === true ? false : true
                                                    setError(x)
                                                    if (TestEmail === false) {
                                                        setTimeout(() => {
                                                            const y = { ...snackBar }
                                                            y.open = true
                                                            y.id = 1
                                                            y.message = 'E-mail inválido'
                                                            setSnackBar(y)
                                                        }, 500)
                                                    }
                                                }}
                                                autoComplete=""
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: 0
                                                    }
                                                }}
                                                sx={{
                                                    maxWidth: 300, borderRadius: 0, "& .MuiOutlinedInput-root": {
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: theme.palette.secondary.main
                                                        }
                                                    }
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                margin="none"
                                                required
                                                name="password"
                                                label="Senha"
                                                type="password"
                                                error={error.senha}
                                                value={form.senha}
                                                id="password"
                                                onChange={(value) => {
                                                    const x = { ...form }
                                                    x.senha = value.target.value
                                                    setForm(x)
                                                }}
                                                onBlur={(value) => {
                                                    const x = { ...error }
                                                    x.senha = value.target.value === '' ? true : false
                                                    setError(x)
                                                    if (value.target.value === '') {
                                                        setTimeout(() => {
                                                            const y = { ...snackBar }
                                                            y.open = true
                                                            y.id = 1
                                                            y.message = 'Senha não pode ser vazia'
                                                            setSnackBar(y)
                                                        }, 500)
                                                    }
                                                }}
                                                autoComplete="current-password"
                                                InputLabelProps={{
                                                    style: {
                                                        color: theme.palette.text.secondary,
                                                        borderColor: '#eee',
                                                        borderRadius: 0,
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: 0
                                                    }
                                                }}
                                                sx={{
                                                    maxWidth: 300, borderRadius: 0, "& .MuiOutlinedInput-root": {
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: theme.palette.secondary.main
                                                        }
                                                    }
                                                }}
                                            />
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ maxWidth: 300, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                                onClick={() => {
                                                    if (error.email || form.email === '') {
                                                        const y = { ...snackBar }
                                                        y.open = true
                                                        y.id = 1
                                                        y.message = 'Campo "E-mail" precisa ser preenchido'
                                                        setSnackBar(y)
                                                        return
                                                    }
                                                    if (error.senha || form.senha === '') {
                                                        const y = { ...snackBar }
                                                        y.open = true
                                                        y.id = 1
                                                        y.message = 'Campo "Senha" precisa ser preenchido'
                                                        setSnackBar(y)
                                                        return
                                                    }
                                                    function Fcallback(callback) {
                                                        if (callback?.status !== 200) {
                                                            const y = { ...snackBar }
                                                            y.open = true
                                                            y.id = 1
                                                            y.message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível encontrar os seus dados'
                                                            setSnackBar(y)
                                                            return
                                                        }
                                                        user.changeData(callback.apiReturn.apiReturn)
                                                    }
                                                    loginClientes(form.email, form.senha, Fcallback)
                                                }}
                                            >Entrar</Button>
                                            <Button disableTouchRipple disableFocusRipple sx={{
                                                display: 'flex', justifyContent: 'flex-end', '&:hover': {
                                                    backgroundColor: 'transparent'
                                                }
                                            }}
                                                onClick={() => {
                                                    const newRedefinir = { ...redefinir }
                                                    newRedefinir.open = true
                                                    setRedefinir(newRedefinir)
                                                }}
                                            >
                                                <Typography textAlign={'end'} fontWeight={'bold'} sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>Esqueceu a senha?</Typography>
                                            </Button>
                                        </Box>
                                        :
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', rowGap: 2, width: '100%', alignSelf: 'center', [theme.breakpoints.down('md')]: {
                                                width: '100%'
                                            },
                                        }}>
                                            <TextField
                                                required
                                                fullWidth
                                                value={form.cpf}
                                                id="cpf"
                                                name="cpf"
                                                label="CPF"
                                                error={error.cpf}
                                                onChange={(value) => {
                                                    const x = { ...form }
                                                    x.cpf = cpfMask(value.target.value)
                                                    setForm(x)
                                                }}
                                                onBlur={(value) => {
                                                    const x = { ...error }
                                                    const testCpf = TestaCPF(value.target.value)
                                                    x.cpf = testCpf === true ? false : true
                                                    setError(x)
                                                    if (testCpf === false) {
                                                        setTimeout(() => {
                                                            const y = { ...snackBar }
                                                            y.open = true
                                                            y.id = 1
                                                            y.message = 'CPF inválido'
                                                            setSnackBar(y)
                                                        }, 500)
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: theme.palette.text.secondary,
                                                        borderColor: '#eee'
                                                    }
                                                }}
                                                autoComplete="cpf"
                                                autoFocus
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: 5,
                                                    }
                                                }}
                                            />
                                            <Box sx={{ display: 'flex' }}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    value={form.primeiro_nome}
                                                    id="primeiro_nome"
                                                    name="primeiro_nome"
                                                    label="Nome"
                                                    onChange={(value) => {
                                                        const x = { ...form }
                                                        x.primeiro_nome = value.target.value
                                                        setForm(x)
                                                    }}
                                                    onBlur={(value) => {
                                                        const x = { ...error }
                                                        x.primeiro_nome = value.target.value === '' ? false : true
                                                        setError(x)
                                                        if (value.target.value === '') {
                                                            setTimeout(() => {
                                                                const y = { ...snackBar }
                                                                y.open = true
                                                                y.id = 1
                                                                y.message = 'Nome precisa ser preenchido'
                                                                setSnackBar(y)
                                                            }, 500)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: theme.palette.text.secondary,
                                                            borderColor: '#eee'
                                                        }
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: 5,
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    required
                                                    fullWidth
                                                    value={form.sobrenome}
                                                    id="sobrenome"
                                                    name="sobrenome"
                                                    label="Sobrenome"
                                                    onChange={(value) => {
                                                        const x = { ...form }
                                                        x.sobrenome = value.target.value
                                                        setForm(x)
                                                    }}
                                                    onBlur={(value) => {
                                                        const x = { ...error }
                                                        x.sobrenome = value.target.value === '' ? false : true
                                                        setError(x)
                                                        if (value.target.value === '') {
                                                            setTimeout(() => {
                                                                const y = { ...snackBar }
                                                                y.open = true
                                                                y.id = 1
                                                                y.message = 'Sobrenome precisa ser preenchido'
                                                                setSnackBar(y)
                                                            }, 500)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: theme.palette.text.secondary,
                                                            borderColor: '#eee'
                                                        }
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: 5,
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex' }}>
                                                <TextField
                                                    fullWidth
                                                    margin="none"
                                                    required
                                                    value={form.email}
                                                    error={error.email}
                                                    id="email"
                                                    name="email"
                                                    label="E-mail"
                                                    onChange={(value) => {
                                                        const x = { ...form }
                                                        x.email = value.target.value
                                                        setForm(x)
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: theme.palette.text.secondary,
                                                            borderColor: '#eee'
                                                        }
                                                    }}
                                                    onBlur={(value) => {
                                                        const x = { ...error }
                                                        const TestEmail = TestaEMAIL(value.target.value)
                                                        x.email = TestEmail === true ? false : true
                                                        setError(x)
                                                        if (TestEmail === false) {
                                                            setTimeout(() => {
                                                                const y = { ...snackBar }
                                                                y.open = true
                                                                y.id = 1
                                                                y.message = 'E-mail inválido'
                                                                setSnackBar(y)
                                                            }, 500)
                                                        }
                                                    }}
                                                    autoComplete=""
                                                    inputProps={{
                                                        style: {
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: 5
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    margin="none"
                                                    required
                                                    name="password"
                                                    label="Senha"
                                                    type="password"
                                                    error={error.senha}
                                                    value={form.senha}
                                                    id="password"
                                                    onChange={(value) => {
                                                        const x = { ...form }
                                                        x.senha = value.target.value
                                                        setForm(x)
                                                    }}
                                                    onBlur={(value) => {
                                                        const x = { ...error }
                                                        x.senha = value.target.value === '' ? true : false
                                                        setError(x)
                                                        if (value.target.value === '') {
                                                            setTimeout(() => {
                                                                const y = { ...snackBar }
                                                                y.open = true
                                                                y.id = 1
                                                                y.message = 'Senha não pode ser vazia'
                                                                setSnackBar(y)
                                                            }, 500)
                                                        }
                                                    }}
                                                    autoComplete="current-password"
                                                    InputLabelProps={{
                                                        style: {
                                                            color: theme.palette.text.secondary,
                                                            borderColor: '#eee',
                                                        }
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: 5,
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <TextField
                                                required
                                                fullWidth
                                                value={form.celular}
                                                id="celular"
                                                name="celular"
                                                label="Celular"
                                                error={error.celular}
                                                onChange={(value) => {
                                                    const x = { ...form }
                                                    x.celular = celularMask(value.target.value)
                                                    setForm(x)
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: theme.palette.text.secondary,
                                                        borderColor: '#eee'
                                                    }
                                                }}
                                                onBlur={(value) => {
                                                    const x = { ...error }
                                                    const validaCelular = TestaCELULAR(value.target.value)
                                                    x.celular = validaCelular ? false : true
                                                    setError(x)
                                                    if (validaCelular === false) {
                                                        setTimeout(() => {
                                                            const y = { ...snackBar }
                                                            y.open = true
                                                            y.id = 1
                                                            y.message = 'Celular inválido'
                                                            setSnackBar(y)
                                                        }, 500)
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: 5,
                                                    }
                                                }}
                                            />
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    function Fcallback(callback) {
                                                        if (callback?.status !== 200) {
                                                            const y = { ...snackBar }
                                                            y.open = true
                                                            y.id = 1
                                                            y.message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível cadastrar o usuário'
                                                            setSnackBar(y)
                                                            return
                                                        }
                                                        user.changeData(callback.apiReturn.apiReturn)
                                                    }
                                                    insertClientes(form.cpf, form.email, form.senha, form.celular, form.primeiro_nome, form.sobrenome, Fcallback)
                                                }}
                                                sx={{ mb: 2, backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                            >Registrar</Button>
                                            <Button onClick={() => {
                                                props.setModalPolitica(true)
                                            }}>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>Ao se cadastrar você concorda com nossa <span style={{ fontWeight: 'bold' }}>Política de privacidade</span></Typography>
                                            </Button>
                                        </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <ModalLoading ative={modalAtive} closeModal={closeModal} />
            <ModalInfo ative={modalInfo.open} closeModal={closeModalInfo} body={modalInfo.body} />
            <SnackBarAlert ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <StatusScreen canDesactive={true} form={form} active={statusScreen.active} id={statusScreen.id} paymentMethod={statusScreen.paymentMethod} desactive={desactiveStatusScreen} serverId={statusScreen.serverId} dateCreated={statusScreen.dateCreated} />
        </Box >
    )
}