import React from "react";
import { useTheme, useThemeProps } from '@mui/material/styles';
import { Box, IconButton, Menu, MenuItem, Divider, Badge, Button, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import moment from "moment";
export const Footer = (props) => {
    const theme = useTheme()
    return (
        <Box sx={{
            width: '100%', backgroundColor: 'rgba(0,0,0,0.9)', gap: 3, height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5,
            [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-start'
            },
        }}>
            <Box sx={{
                width: '70%', display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    gap: 5
                },
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, alignItems: 'center' }}>
                    <img src="../assets/images/logos/icon.png" style={{ objectFit: 'contain', width: 50 }} />
                    <Typography fontWeight={'bold'} fontSize={15} textAlign={'center'} color={'#fff'}>Produto Brasil Farma CD</Typography>
                    <Typography fontWeight={'bold'} color={'#fff'}>43.633.451/0001-79</Typography>
                    <Button onClick={() => {
                        props.setModalPolitica(true)
                    }}>
                        <Typography sx={{ color: '#fff', textTransform: 'none', fontWeight: 'bold' }}>Política de privacidade</Typography>
                    </Button>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, alignItems: 'flex-start',
                    [theme.breakpoints.down('md')]: {
                        alignItems: 'center',
                    },
                }}>
                    <Typography fontWeight={'bold'} sx={{ fontSize: 30 }} color={'#fff'}>Contato</Typography>
                    <Typography color={'#fff'}>SAC: (49) 9 9826-1624</Typography>
                    <Typography color={'#fff'}>sac@herpscalm.com.br</Typography>
                    <Typography color={'#fff'}>De 2ª a 6ª das 8h às 17h</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, alignItems: 'flex-start',
                    [theme.breakpoints.down('md')]: {
                        alignItems: 'center',
                    },
                }}>
                    <Typography fontWeight={'bold'} sx={{ fontSize: 30 }} color={'#fff'}>Pagamento</Typography>
                    <Typography color={'#fff'} sx={{
                        [theme.breakpoints.down('md')]: {
                            textAlign: 'center'
                        },
                    }}>Formas de pagamentos aceitos pelo Mercado Pago</Typography>
                    <Box sx={{ paddingTop: 2 }}>
                        <img src="../assets/images/footer/mercadoP.png" />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, alignItems: 'flex-start', height: '100%' }}>
                    <Typography fontWeight={'bold'} sx={{ fontSize: 30 }} color={'#fff'}>Redes Sociais</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <IconButton
                            href="https://www.instagram.com/herpscalm"
                            target="_blank"
                            rel="noopener noreferrer">
                            <InstagramIcon sx={{ color: '#fff', fontSize: 50 }} />
                        </IconButton>
                        <IconButton
                            href="https://www.facebook.com/herpscalm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FacebookIcon sx={{ color: '#fff', fontSize: 50 }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: '50%', borderTop: 1, borderColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                <Typography textAlign={'center'} color={'#fff'}>{'Copyright© ' + moment().format('YYYY') + ' - HERPSCALM'}</Typography>
            </Box>
        </Box>
    )
}