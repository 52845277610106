import { useRef, useState, useContext } from "react";
import { Box, Button, Collapse, Typography, useTheme, useMediaQuery, Grow } from "@mui/material";
import { OneProduct } from "./combos/oneProduct";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useInView } from 'react-intersection-observer';
import { Product } from "../../../contexts/product";
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';


export function SectionCombos({ }) {
    const theme = useTheme()
    var swiperRef = useRef()
    const produto = useContext(Product)

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const tytleStyle = {
        fontWeight: 'bold', fontSize: isSmallScreen ? 30 : 40, textAlign: 'center', color: theme.palette.text.primary
    }
    const subtitleStyle = {
        fontSize: isSmallScreen ? 16 : 20,
        color: theme.palette.text.primary,
        textAlign: 'center',
        maxWidth: 900
    }

    const objTitle = {
        fontWeight: 'bold', fontSize: 25, textAlign: 'left', color: theme.palette.text.primary
    }
    const objSubtitle = {
        fontSize: 20, textAlign: 'left', color: theme.palette.text.primary
    }
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });
    return (
        <Grow in={inView} timeout={1000}>
            <Box ref={ref} id={'inicio'} sx={{ display: 'flex', width: '100%', flexDirection: 'column', flex: 1, padding: isSmallScreen ? 2 : 10, paddingTop: 2, paddingBottom: 2, gap: 5, alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography sx={tytleStyle}>OFERTAS ESPECIAIS E PACOTES ECONÔMICOS</Typography>
                    <Typography sx={subtitleStyle}>Aproveite nossos pacotes e economize.</Typography>
                </Box>
                <Box sx={{ maxWidth: 1300, width: '100%', margin: '0 auto' }}>
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={1}
                        modules={[Pagination, Navigation]}
                        style={{ overflowY: 'visible' }}
                        breakpoints={{
                            900: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            }
                        }}
                        navigation={true}
                    >
                        {Array.isArray(produto?.data?.prices) ? produto?.data?.prices.map((e, idx) => {
                            const get1Unidade = data.filter((e) => e.qtd === 1);
                            const unitPreco = get1Unidade[0].price;
                            return (
                                <SwiperSlide key={idx} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <OneProduct data={e} unitPreco={unitPreco} />
                                </SwiperSlide>
                            );
                        }) : null}
                    </Swiper>
                </Box>
            </Box>
        </Grow>
    )
}




{/* <Box>
    <ButtonComprar sx={{ background: theme.palette.tertiary.main, color: '#fff', fontWeight: 'bold' }} />
</Box> */}

const data = [
    {
        nome: '1 Unidade',
        price: 139.90,
        qtd: 1,
        image: '../assets/images/home/section2/herpscalm.png'
    },
    {
        nome: '3 Unidades',
        price: 271.05,
        qtd: 3,
        image: '../assets/images/home/section2/herpscalm.png',
        freteGratis: true,
    },
    {
        nome: '6 Unidades',
        price: 458.70,
        qtd: 6,
        freteGratis: true,
        image: '../assets/images/home/section2/herpscalm.png'
    },
    {
        nome: '6 Unidades',
        price: 458.70,
        qtd: 6,
        freteGratis: true,
        image: '../assets/images/home/section2/herpscalm.png'
    },

]